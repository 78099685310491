import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IShift, ITypeShift } from '@shared/repositories/sdweb.repository';
import { ShiftRepository } from '@shared/repositories/shift.repository';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface IShiftState {
	typeShifts: ITypeShift[];
	workShifts: IShift[];
}

const DefaultShiftState = {
	typeShifts: [],
	workShifts: [],
}

@Injectable({ providedIn: "root" })
@StoreConfig({name: "shiftStore"})
export class ShiftStore extends Store<IShiftState> {
	constructor(
		private _shiftRepository: ShiftRepository,
		) {
		super(DefaultShiftState);
	}

	public init(): Observable<IShiftState> {
		const typeShifts$ = this._shiftRepository.getTypeShifts();
		const workShifts$ = this._shiftRepository.getWorkShifts();

		return forkJoin({
			typeShifts: typeShifts$,
			workShifts: workShifts$,
		})
			.pipe(
				tap((res) => {
					this.update({
						typeShifts: res.typeShifts,
						workShifts: res.workShifts,
					});
				})
			)
	}
}
