import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ISDTaskFilter, SimpleNomRequest} from '../../modules/sd/models/sd-task.interface';
import {MesNomenclature} from '@store/data/plan/mes-nomenclature';
import {NomenclatureResponse} from '@store/data/nomenclature-response';
import {ISimpleTask} from '../../modules/dashboard/models/dashboard.types';
import {ItemsFilterResponse} from '@store/data/items-filter-response';
import {BaseRepository} from './base-repository';
import {ITool} from '@shared/models/tool';
import {ITaskParameterHistory, ITechnicalMapParams} from '@shared/models/mes-params';
import {ITask} from '../../modules/task/models/task.type';
import {Tool} from '@shared/components/tool-item/models/tool-item.classes';
import {
	ToolRegistration
} from '../../modules/monitoring/modules/unit/components/unit-tabs-area/components/unit-tool/components/tool-registration/models/tool-registration.classes';
import {ToolUnitSlot} from '../../modules/admin/components/tool-table/models/tool-table.classes';

export interface ICreateRepairTask {
	actionsToDefect: string;
	authorComment: string;
	errorCode: string;
	repairPriorityId: number;
	repairTaskTypeId: number;
	repairUnitTagId: number;
	unitId: number;
	unitTags: number[];
}

export interface IToolUnitSlot {
	id: number;
	slotName: string;
	unitId: number;
	activationDeviceParameterId: number;
	activationDeviceParameterName: string;
	paletteDeviceParameterId: number;
	paletteDeviceParameterName: string;
}

export interface IToolRegistration {
	id: number;
	toolId: number;
	toolName: string;
	userId: number;
	insertDate: Date;
	removeDate: Date;
	slotName: string;
	toolUnitSlotId: number;
	lifeTime: number;
	toolUnitSlotItem: IToolUnitSlot;
}

export interface IPlanTask {
	id: number;
	text: string;
	type: string;
	start_date: Date;
	duration: number;
	progress: number;
	owner_id: string;
	parent_task: string;
	techProcessId: number;
	locationId: number;
	unitId: number;
	techProcessOrder: number;
	priority: number;
	count: number;
}

export interface IPlanLink {
	id: string;
	source: string;
	target: string;
	type: string;
}

export interface ISdUnitData {
	id: number;
	name: string;
	inventoryNumber: string;
}

export interface IPlanLocationData {
	locationId: number;
	locationName: string;
	machineCount: number;
	longestDuration: number;
	machineData: ISdUnitData[];
}

export interface IPlanData {
	planTasks: IPlanTask[];
	planLinks: IPlanLink[];
	planLocation: {
		[key: number]: IPlanLocationData[]
	};
}

export interface ITypeShift {
	id: number;
	name: string;
	isDefault: boolean;
}

export interface IShift {
	id: number;
	name: string;
	description: null;
	start: string;
	end: string;
	order: number;
	repeatTime: number;
	shiftTypeId: number;
	duration: number;
}

export interface INewShiftRecord {
	shiftId: number;
	unitId: number;
	userId: number;
	date: string;
	isSingle?: boolean;
}

export interface ILoaderShiftRecord extends Pick<IShiftRecord, 'shiftId' | 'unitId'> {
	date: Date | string;
}

export interface IShiftRecord extends INewShiftRecord {
	id: number;
	isCalculated: boolean;
}

export interface IPutShiftRecord extends Partial<INewShiftRecord> {
	id: number;
}

export interface IBrigade {
	id: number;
	remoteIdStr?: string;
	name: string;
	parameterId: number;
	color?: string;
	weight: number;
	archived: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class SdwebRepository extends BaseRepository {
	public getBrigades(): Observable<IBrigade[]> {
		const url = `${this._config.javaApiUrl}/sdweb/getDictionaryBy/team`;

		return this._http.get<IBrigade[]>(url);
	}

	public getUserIdsByBrigades(brigadeIds: number[]): Observable<number[]> {
		const url = `${this._config.javaApiUrl}/sdweb/shift/user-team`;

		return this._http.post<number[]>(url, {value: brigadeIds});
	}

	public addRepairTask(
		data: ICreateRepairTask
	): Observable<{ key: string | number } | null> {
		const url = `${this._config.javaApiUrl}/sdweb/repair/task/add`;

		return this._http.post<{ key: string | number } | null>(url, data);
	}

	public getTasks(
		data: ISDTaskFilter
	): Observable<ISimpleTask[]> {
		const url = `${this._config.javaApiUrl}/sdweb/task/task-response/filter`;
		return this._http.post<ISimpleTask[]>(url, data);
	}

	public saveToolUnitSlot(payload: ToolUnitSlot): Observable<IToolUnitSlot> {
		const url = `${this._config.javaApiUrl}/sdweb/tool/save/toolunitslot`;

		return this._http.post<IToolUnitSlot>(url, payload);
	}

	public getToolsRegistrationsInSlots(
		payload: number[]
	): Observable<IToolRegistration[]> {
		const url = `${this._config.javaApiUrl}/sdweb/tool/get/toolregistration`;

		return this._http.post<IToolRegistration[]>(url, payload);
	}

	public saveToolRegistration(
		payload: ToolRegistration
	): Observable<IToolRegistration> {
		const url = `${this._config.javaApiUrl}/sdweb/tool/save/toolregistration`;

		return this._http.post<IToolRegistration>(url, payload);
	}

	public deleteToolUnitSlot(id: number): Observable<boolean> {
		const url = `${this._config.javaApiUrl}/sdweb/tool/delete/toolunitslot/${id}`;

		return this._http.delete<boolean>(url);
	}

	public getAllUnitSlots(taskId: number): Observable<IToolUnitSlot[] | false> {
		const url = `${this._config.javaApiUrl}/sdweb/tool/toolunitslot/${taskId}`;

		return this._http.get<IToolUnitSlot[] | false>(url);
	}

	public getAllTool(
		filters: { creatorsUserIds: number[] }
	): Observable<ITool[]> {
		const url = `${this._config.javaApiUrl}/sdweb/tool/gettools`;

		return this._http.post<ITool[]>(url, filters);
	}

	public getToolsUsage(toolIds: number[]): Observable<{
		[key: number]: number,
	}> {
		const url = `${this._config.javaApiUrl}/sdweb/tool/gettoolsusage`;

		return this._http.post<{ [key: number]: number }>(url, toolIds);
	}

	public saveTool(tool: Tool): Observable<Tool> {
		const url = `${this._config.javaApiUrl}/sdweb/tool/savetool`;

		return this._http.post<Tool>(url, tool);
	}

	public getToolsByIds(ids: number[]): Observable<Tool[]> {
		const url = `${this._config.javaApiUrl}/sdweb/tool/gettoolsbyids`;

		return this._http.post<Tool[]>(url, ids);
	}

	public deleteTool(id: number): Observable<boolean> {
		const url = `${this._config.javaApiUrl}/sdweb/tool/tool/${id}`;

		return this._http.delete<boolean>(url);
	}

	// ***********************************************************************
	// Для планирования
	// ***********************************************************************3


	public addSimpleTask(data: SimpleNomRequest): Observable<ISimpleTask> {
		return this._http.post<ISimpleTask>(
			`${this._config.javaApiUrl}/sdweb/task/custom`, data
		);
	}

	public updateSimpleTask(data: SimpleNomRequest, statusId: number): Observable<ISimpleTask> {
		return this._http.post<ISimpleTask>(
			`${this._config.javaApiUrl}/sdweb/task/custom/` + statusId, data
		);
	}

	public planByOrderId(orderIds: number[], unitIds?: number[]): Observable<IPlanData> {
		const payLoad = {value: orderIds, filter: unitIds};
		const url = `${this._config.javaApiUrl}/sdweb/plan/`;

		return this._http.post<IPlanData>(url, payLoad);
	}

	public getNomenclatureFields(nomId: number): Observable<NomenclatureResponse> {
		const url = `${this._config.javaApiUrl}/sdweb/nomenclature/get-nomenclature-fields/` + nomId;
		return this._http.get<NomenclatureResponse>(url);
	}

	public getTaskFields(taskId: number): Observable<NomenclatureResponse> {
		const url = `${this._config.javaApiUrl}/sdweb/nomenclature/get-task-fields/` + taskId;
		return this._http.get<NomenclatureResponse>(url);
	}

	public getUserFields(userId: number): Observable<NomenclatureResponse> {
		const url = `${this._config.javaApiUrl}/sdweb/nomenclature/get-user-fields/` + userId;
		return this._http.get<NomenclatureResponse>(url);
	}

	public findNomenclatureByGroup(findStr: string, filter: ItemsFilterResponse): Observable<MesNomenclature[]> {
		filter.findStr = findStr;
		const url = `${this._config.javaApiUrl}/sdweb/nomenclature/find`;
		return this._http.post<MesNomenclature[]>(url, filter);
	}

	public getTaskBodyById(taskId: number): Observable<ITask> {
		const url = `${this._config.javaApiUrl}/sdweb/task/get-task-body-by-id/` + taskId;
		return this._http.get<ITask>(url);
	}

	public findTaskById(id: string): Observable<ISimpleTask[]> {
		const url = `${this._config.javaApiUrl}/sdweb/task/find-task-by-id/${id}`;
		return this._http.get<ISimpleTask[]>(url);
	}

	public getDTO<T>(
		ids: number[], entityName: string, byFild: string
	): Observable<T> {
		const payLoad = {value: ids};
		const url = `${this._config.javaApiUrl}/sdweb/plan/get/` +
			`${entityName}/${byFild}`;

		return this._http.post<T>(url, payLoad);
	}

	public saveDTO<I, O>(data: I, entityName: string): Observable<O> {
		const url = `${this._config.javaApiUrl}/sdweb/plan/save/` + entityName;

		return this._http.post<O>(url, data);
	}

	public deleteDTO(ids: number[], entityName: string): Observable<true> {
		const payLoad = {value: ids};
		const url = `${this._config.javaApiUrl}/sdweb/plan/delete/` + entityName;

		return this._http.post<true>(url, payLoad);
	}

	public getParamsByTaskTypeAndUniType(
		taskType: number,
		unitType: number
	): Observable<ITechnicalMapParams[]> {
		const url = `${this._config.javaApiUrl}/sdweb/plan/get-params?taskType=${taskType}&unitType=${unitType}`;

		return this._http.get<ITechnicalMapParams[]>(url);
	}

	public getTaskHistoryParams(taskId: number): Observable<ITaskParameterHistory[]> {
		const url = `${this._config.javaApiUrl}/sdweb/plan/history-params/${taskId}`;

		return this._http.get<ITaskParameterHistory[]>(url);
	}

	public saveParams(data: ITaskParameterHistory[]): Observable<boolean> {
		const url = `${this._config.javaApiUrl}/sdweb/plan/history-params`;

		return this._http.post<boolean>(url, data);
	}
}
