// Для упрощения доступа к DTO
// (Теребуется вручную синхронизировать с EntityConstantEnums на беке)
export enum EntityConstantEnums {
	tech_map = 'tech_map',
	tech_map_nomenclature = 'tech_map_nomenclature',
	technical_map_unit_type = 'technical_map_unit_type',
	technical_map_parameter = 'technical_map_parameter',
	unit_type = 'unit_type',

	nomenclature = 'nomenclature',
	nomenclature_group = 'nomenclature_group',
	nomenclature_group_ref = 'nomenclature_group_ref',

	nomenclature_movement = 'nomenclature_movement',
	store = 'store',
	store_nomenclature = 'store_nomenclature',


	mes_parameter = 'mes_parameter',
	mes_nomenclature_parameter = 'mes_nomenclature_parameter',
	value_dictionary = 'value_dictionary',

	counterparty = 'counterparty',
	order = 'order',
	order_nomenclature = 'order_nomenclature',

	nomenclature_parameter_status = 'nomenclature_parameter_status',
	nomenclature_parameter_display = 'nomenclature_parameter_display',

	user_add_field_value = 'user_add_field_value',

	task = 'task',
	task_add_field_value = 'task_add_field_value',
	task_nomenclature = 'task_nomenclature',
	nomenclature_group_bind = 'nomenclature_group_bind',

	shift = 'shift',
}
