import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser } from '@store/types';
import { SDUser } from '../../modules/sd/models/sd-user.interface';
import { ApiRepository } from '@shared/repositories/api.repository';
import { map } from 'rxjs/operators';

export interface IUsersState {
	users: Map<number, IUser>;
}

const DefaultUsersState: IUsersState = {
	users: null,
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'userStore' })
export class UsersStore extends Store<IUsersState>{
	constructor(
		private _apiRepository: ApiRepository,
	) {
		super(DefaultUsersState);
	}

	init(): Observable<IUsersState> {
		const employees$: Observable<SDUser[]> = this._apiRepository.getEmployees(`auth/user/get/all`);

		return employees$.pipe(
			map((res) => {
				const data: IUsersState = {
					users: new Map(),
				}

				res.forEach(user => {
					data.users.set(user.id, user as IUser);
				})
				this.update(data);
				return data;
			})
		)
	}
}
