import {Observable} from 'rxjs';
import {INewShiftRecord, IPutShiftRecord, IShift, IShiftRecord, ITypeShift, SdwebRepository} from './sdweb.repository';
import {Injectable} from '@angular/core';
import {mergeMap} from 'rxjs/operators';
import {ConfigService} from '@shared/services/config.service';
import {HttpClient} from '@angular/common/http';
import {EntityConstantEnums} from '@shared/constants/entity-constant.enums';
import {BaseRepository} from './base-repository';
import {IInterval} from '@shared/models/interval';

@Injectable({providedIn: 'root'})
export class ShiftRepository extends BaseRepository {
	constructor(
		private mesApi: SdwebRepository,
		private _http2: HttpClient,
		private _configService2: ConfigService,
	) {
		super(_http2, _configService2);
	}

	public getShiftRecordsByUnitClasses(
		body: { value: number[], intervalDate: IInterval }
	): Observable<IShiftRecord[]> {
		const url = `${this._config.javaApiUrl}/sdweb/shift/unit`;

		return this._http.post<IShiftRecord[]>(url, body)
			.pipe(mergeMap(i => [
				i.map(k => ({
					...k,
					date: k.date.split('[UTC]')[0],
				})),
			]));
	}

	public continuesDeleteShiftRecords(ids: IShiftRecord[]): Observable<boolean> {
		const url =
			`${this._config.javaApiUrl}/sdweb/shift/unit-user/continues-delete`;

		return this._http.delete<boolean>(url, {body: ids});
	}

	public singleDeleteShiftRecords(ids: IShiftRecord[]): Observable<boolean> {
		const url =
			`${this._config.javaApiUrl}/sdweb/shift/unit-user/single-delete`;

		return this._http.delete<boolean>(url, {body: ids});
	}

	public createShiftRecords(
		records: INewShiftRecord[]
	): Observable<IShiftRecord[]> {
		const url = `${this._config.javaApiUrl}/sdweb/shift/unit-user`;

		return this._http.post<IShiftRecord[]>(url, records)
			.pipe(mergeMap(i => [
				i.map(k => ({
					...k,
					date: k.date.split('[UTC]')[0],
				})),
			]));
	}

	public getTypeShifts(): Observable<ITypeShift[]> {
		const url = `${this._config.javaApiUrl}/sdweb/shift/shift-type`;

		return this._http.get<ITypeShift[]>(url);
	}

	public getWorkShiftsByTypeShift(shiftId: number): Observable<IShift[]> {
		const url = `${this._config.javaApiUrl}/sdweb/shift/${shiftId}`;

		return this._http.get<IShift[]>(url);
	}

	public getWorkShifts(): Observable<IShift[]> {
		return this.mesApi.getDTO<IShift[]>(
			null,
			EntityConstantEnums.shift,
			null
		);
	}

	public putShiftRecords(
		records: IPutShiftRecord[]
	): Observable<IShiftRecord[]> {
		const url = `${this._config.javaApiUrl}/sdweb/shift/unit-user`;

		return this._http.post<IShiftRecord[]>(url, records)
			.pipe(mergeMap(i => [
				i.map(k => ({
					...k,
					date: k.date.split('[UTC]')[0],
				})),
			]));
	}

	public getShiftRecordsByUsers(
		body: { value: number[], intervalDate: IInterval }
	): Observable<IShiftRecord[]> {
		const url = `${this._config.javaApiUrl}/sdweb/shift/user`;

		return this._http.post<IShiftRecord[]>(url, body);
	}

	public createShiftReport(
		interval: IInterval,
		unitIds: number[],
		shiftIds: number[],
	): Observable<string[]> {
		const url = `${this._config.javaApiUrl}/report/jasper/custom/shift-report`;

		return this._http.post<string[]>(url, {
			intervalDate: interval,
			value: unitIds,
			filter: shiftIds,
		});
	}
}
