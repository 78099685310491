import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IUsersState, UsersStore } from '@store/user-store/users.store';
import { IUser } from '@store/types';

@Injectable({
	providedIn: 'root',
})
export class UsersSelector extends Query<IUsersState> {
	constructor(
		protected store: UsersStore,
	) {
		super(store);
	}

	public getAllUsers(): IUser[] {
		return Array.from(this.store.getValue().users.values());
	}

	public getUserById(userId: number): IUser {
		return this.store.getValue().users.get(userId);
	}

	public getCurrentUser(): IUser {
		const user: { userId: number } = JSON.parse(
			localStorage.getItem('currentUser')
		);

		if (!user) {
			return null;
		}

		return this.getUserById(user.userId);
	}
}
